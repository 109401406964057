
import barba from '@barba/core';
import anime from 'animejs';

export default class{

	constructor(){
        barba.hooks.afterOnce((data) => {
            this.init()
        })
        barba.hooks.after((data) => {
            this.init()
        });
    }
    init (){
       const heroCatch = document.querySelector("#l-hero__catch")
        if(heroCatch){
            let regexp = /[\u{3000}-\u{301C}\u{3041}-\u{3093}\u{309B}-\u{309E}]/mu;
            let heroPrimary = document.querySelectorAll('.l-hero__catch-text')
            if(heroPrimary.length > 0){
                heroPrimary.forEach( (h,i) => {
                    let pr_str = h.textContent.split("");
                    let str = "";
                    pr_str.map( s => {
                        str += "<span class='hero__str'>" + s  + "</span>";
                    })
                        
                    h.innerHTML = str;
                })
            }

            let heroSecondary = document.querySelectorAll('.l-hero__catch-ja')
            if(heroSecondary){
                heroSecondary.forEach( (h,i) => {
                    let sc_str = h.textContent.split("");
                    let str = "";
                    sc_str.map( s => {
                        str += "<span class='hero__str'>" + s  + "</span>";
                    })
                        
                    h.innerHTML = str;
                })
            }

            
    
            setTimeout(function(){
                let str_animation =  anime.timeline();
                str_animation.add({
                    targets : '.l-hero__image--bg',
                    scale: [1.5,1],
                    opacity:[0,1],
                    easing: "easeOutExpo",
                    duration:2000
                })
                str_animation.add({
                    targets : '.l-hero__catch-en .hero__str',
                    scale: [5,1],
                    opacity: [0,1],
                    translateZ: 0,
                    easing: "easeOutExpo",
                    duration: 950,
                    delay: function(el, i) {
                        return (i * 70);
                    },
                },"-=1300")
                .add({
                    targets : '.l-hero__catch-ja .hero__str',
                    scale: [1.3,1],
                    translateX: ["20%",0],
                    opacity:[0,1],
                    
                    easing: "easeOutExpo",
                    delay: function(el, i) {
                        return (i * 70);
                    },
                    duration:800
                },"-=800")
            },600)
       }
    }
}