
import barba from '@barba/core';
import Swiper from 'swiper';

export default class{

	constructor(){
        barba.hooks.afterOnce((data) => {
            this.init()
        })
        barba.hooks.after((data) => {
            this.init()
        });
    }
    
    init(){
        const joblinks = document.querySelectorAll('.p-index__jobs--btn')
        const jobimg = document.querySelector('.p-index__jobs--img')

        joblinks.forEach((element,index) => {
            const name = element.getAttribute('data-name')
            element.addEventListener("mouseenter",() => {
                jobimg.classList.add('-' + name )
            }) //マウスが乗ったときに発火

            element.addEventListener("mouseleave",() => {
                jobimg.classList.remove('-' + name )
            }) //マウスが離れたときに発火
        })


        const recruitlinks = document.querySelectorAll('.p-index__recruit--link')
        const recruitwrapper = document.querySelector('.p-index__recruit--wrapper')

        recruitlinks.forEach((element,index) => {
            const name = element.getAttribute('data-name')
            element.addEventListener("mouseenter",() => {
                recruitwrapper.classList.add('-' + name )
            }) //マウスが乗ったときに発火

            element.addEventListener("mouseleave",() => {
                recruitwrapper.classList.remove('-' + name )
            }) //マウスが離れたときに発火
        })
    }
}