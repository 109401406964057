import barba from '@barba/core';
import KeenSlider from 'keen-slider';


export default class{

	constructor(){
        barba.hooks.afterOnce((data) => {
            this.loopSlider()
        })
        barba.hooks.after((data) => {
            this.loopSlider()
        });
    }
    
    loopSlider(){
        const indexLoop1 = document.querySelector("#my-keen-slider1");
        const indexLoop2 = document.querySelector("#my-keen-slider2");

        if(indexLoop1) {
            const animation = { duration: 14000, easing: (t) => t }

            new KeenSlider('#my-keen-slider1', {
                loop: true,
                //mode:'free',
                renderMode: "performance",
                drag: false,
                slides: {
                    perView: 1.5,
                    spacing: 10,
                },
                breakpoints: {
                    '(min-width: 576px)': {
                        slides: {
                            perView: 5,
                            spacing: 20,
                        },
                    },
                    '(min-width: 768px)': {
                        slides: {
                            perView: 5,
                            spacing: 20,
                        },
                    },
                    '(min-width: 1499px)': {
                        slides: {
                            perView: 5,
                            spacing: 20,
                        },
                    },
                },
                created(s) {
                    s.moveToIdx(2, true, animation)
                },
                updated(s) {
                    s.moveToIdx(s.track.details.abs + 2, true, animation)
                },
                animationEnded(s) {
                    s.moveToIdx(s.track.details.abs + 2, true, animation)
                },
            });
        }
        if(indexLoop2) {
            const animation = { duration: 14000, easing: (t) => t }

            new KeenSlider('#my-keen-slider2', {
                loop: true,
                //mode:'free',
                renderMode: "performance",
                drag: false,
                slides: {
                    perView: 1.5,
                    spacing: 10,
                },
                breakpoints: {
                    '(min-width: 576px)': {
                        slides: {
                            perView: 5,
                            spacing: 20,
                        },
                    },
                    '(min-width: 768px)': {
                        slides: {
                            perView: 5,
                            spacing: 20,
                        },
                    },
                    '(min-width: 1499px)': {
                        slides: {
                            perView: 5,
                            spacing: 20,
                        },
                    },
                },
                created(s) {
                    s.moveToIdx(2, true, animation)
                },
                updated(s) {
                    s.moveToIdx(s.track.details.abs + 2, true, animation)
                },
                animationEnded(s) {
                    s.moveToIdx(s.track.details.abs + 2, true, animation)
                },
            });
        }
    }
}