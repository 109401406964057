import barba from '@barba/core';
import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);


export default class{

	constructor(){

        barba.hooks.afterOnce((data) => {
            this.init()
            this.pictureScroll1()
            this.pictureScroll2()
            this.circle()
            this.circle2()
            this.messageImgFix()
        });
        barba.hooks.beforeLeave((data) => {
            window.removeEventListener("resize",this.resize)
        });
        barba.hooks.after((data) => {
            this.init()
            this.pictureScroll1()
            this.pictureScroll2()
            this.circle()
            this.circle2()
            this.messageImgFix()
        });

    }
    pictureScroll1(){
        this.trigger = document.querySelector('.js-scrollTrigger__picture');
        this.elem = document.querySelectorAll('.js-scrollElem__picture1');
        if(this.elem) {
            this.elem.forEach((el, i) => {
                gsap.to(el, {
                    y: '-15%',
                    scrollTrigger: {
                      trigger: this.trigger,
                      start: 'top center' ,
                      end: '+100% center',  
                      scrub: 1, 
                    }
                });
            })
        }
    }
    pictureScroll2(){
        this.trigger = document.querySelector('.js-scrollTrigger__picture');
        this.elem = document.querySelectorAll('.js-scrollElem__picture2');
        if(this.elem) {
            this.elem.forEach((el, i) => {
                gsap.to(el, {
                    y: '15%',
                    scrollTrigger: {
                      trigger: this.trigger,
                      start: 'top center' ,
                      end: '+100% center',  
                      scrub: 1, 
                    }
                });
            })
        }
    }

    circle(){
        this.trigger = document.querySelector('.js-scrollTrigger__circle');
        this.elem = document.querySelectorAll('.js-scrollElem__circle');
        if(this.elem) {
            this.elem.forEach((el, i) => {
                gsap.to(el, {
                    borderRadius: '0',
                    scrollTrigger: {
                        trigger: this.trigger,
                        start: 'top center' ,
                        end: 'bottom top',  
                        scrub: 1, 
                        // markers: true,
                    }
                });
            })
        }
    }
    circle2(){
        this.trigger = document.querySelector('.js-scrollTrigger__circle2');
        this.elem = document.querySelectorAll('.js-scrollElem__circle2');
        if(this.elem) {
            this.elem.forEach((el, i) => {
                gsap.to(el, {
                    height: '3vh',
                    scrollTrigger: {
                      trigger: this.trigger,
                      start: '-100% center' ,
                      end: 'bottom top',  
                      scrub: 1, 
                    //   markers: true,
                    }
                });
            })
        }
    }

    init(){

        this.indexBlog = false
        this.sticky01 = false
        this.sticky02 = false
        this.faq = false
        this.story = false
        
        const indexBlog = document.querySelector("#p-index__blog")
        if(indexBlog&&(window.innerWidth > 991)){
            setTimeout(() => {
                this.indexBlog = ScrollTrigger.create({
                    trigger: ".p-index__blog--wrapper [data-blog-trigger]",
                    pin: ".p-index__blog--side",
                    start: "top 20%",
                    end: "bottom bottom",
                    // markers: true,
                });
            },100)
        }

        // const indexBlog = document.querySelector("#p-index__blog")
        // if(indexBlog&&(window.innerWidth > 991)){
        //     this.indexBlog = ScrollTrigger.create({
        //         trigger: ".p-index__blog--side",
        //         pin: ".p-index__blog--side",
        //         start: "center center",
        //         end: "bottom top-=-50%",
        //         endTrigger: "#p-index__blog",
        //         markers: true,
        //     });
        // }

        const message01 = document.querySelector("#message01")
        if(message01&&(window.innerWidth > 991)){
            this.sticky01 = ScrollTrigger.create({
                trigger: "#p-message__image01",
                pin: "#p-message__image01",
                start: "center-=220px center",
                endTrigger: "#p-message__content01",
                end: "bottom+=220px bottom",
                // markers: true,
            });
        }

        const message02 = document.querySelector("#message02")
        if(message02&&(window.innerWidth > 991)){
            this.sticky02 = ScrollTrigger.create({
                trigger: "#p-message__image02",
                pin: "#p-message__image02",
                start: "center-=220px center",
                endTrigger: "#p-message__content02",
                end: "bottom+=220px bottom",
                // markers: true,
            });
        }

        const faq = document.querySelector("#p-faq__main")
        if(faq&&(window.innerWidth > 991)){
            this.faq = ScrollTrigger.create({
                trigger: ".p-faq__side",
                pin: ".p-faq__side",
                start: "center center",
                endTrigger: ".p-faq__qa",
                end: "bottom bottom",
                // markers: true,
            });
        }

        const story = document.querySelector(".p-story__interview")
        if(story&&(window.innerWidth > 991)){
            this.story = ScrollTrigger.create({
                trigger: ".p-story__side",
                pin: ".p-story__side",
                start: "center center",
                endTrigger: ".p-story__interview__textbox",
                end: "bottom bottom",
                // markers: true,
            });
        }

        this.resize()
        window.addEventListener("resize",this.resize.bind(this))

        
    }
    resize(){
        if(window.innerWidth > 991){
            if(this.indexBlog){
                this.indexBlog.enable()
            }
            if(this.sticky01){
                this.sticky01.enable()
            }
            if(this.sticky02){
                this.sticky02.enable()
            }
            if(this.faq){
                this.faq.enable()
            }
            if(this.story){
                this.story.enable()
            }
        }else{
            if(this.indexBlog){
                this.indexBlog.disable()
            }
            if(this.sticky01){
                this.sticky01.disable()
            }
            if(this.sticky02){
                this.sticky02.disable()
            }
            if(this.faq){
                this.faq.disable()
            }
            if(this.story){
                this.story.disable()
            }
        }
    }
    // messageImgFix(){
    //     this.trigger = document.querySelector('.p-message__content');
    //     this.elem = document.querySelectorAll('.p-message__side');
    //     if(this.elem) {
    //         this.elem.forEach((el, i) => {
    //             gsap.to(el, {
    //                 scrollTrigger: {
    //                     trigger: this.trigger,
    //                     start: 'top center' ,
    //                     end: 'bottom top',  
    //                     scrub: 1, 
    //                     markers: true,
    //                     pin: true,
    //                 }
    //             });
    //         })
    //     }
    // }
    
}