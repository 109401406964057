
import barba from '@barba/core';
import three from 'three/build/three.min.js';
import vanta from 'vanta/dist/vanta.halo.min'

export default class{
    constructor(){
        barba.hooks.afterOnce((data) => {
            this.init()
        })
        barba.hooks.after((data) => {
            this.init()
        });
    }
    init (){
        const vantaEL = document.querySelector("#vanta")
        if(vantaEL) {
            VANTA.HALO({
                el: "#vanta",
                mouseControls: true,
                touchControls: true,
                gyroControls: false,
                size: 1.3,
                minHeight: 200.00,
                minWidth: 200.00,
                baseColor: 0x29ac29,
                color2: 0xf2e735,
                backgroundColor: 0xededed,
                amplitudeFactor: 0,
                ringFactor: 1.0,
                xOffset: 0,
                yOffset: 0
            })
        }
    }
}