
import barba from '@barba/core';
import Swiper from 'swiper';

export default class{

	constructor(){

        barba.hooks.beforeOnce((data) => {
            this.indexSlider()

        })
        barba.hooks.after((data) => {
            this.indexSlider()
        });
        
    }
    
    indexSlider(){
        const myDelay = 6000;
        const slide = document.querySelectorAll('.small-slider .swiper-slide'); //該当のスライド取得
        const slideLength = slide.length;

        // const fraction = document.querySelectorAll('.small-slider .slide-fraction');
        // const fractionEl = '<span class="circle"><svg viewBox="0 0 42 42"><circle class="circle-01" cx="50%" cy="50%" r="21"></circle><circle class="circle-02" cx="50%" cy="50%" r="21"></circle></svg><svg xmlns="http://www.w3.org/2000/svg" width="14" height="12.002" viewBox="0 0 14 12.002"><path d="M13.867,70.3,8.682,75.8a.574.574,0,0,1-.384.163.528.528,0,0,1-.349-.13.488.488,0,0,1-.035-.706l4.4-4.664H.518A.5.5,0,0,1,0,69.993a.553.553,0,0,1,.518-.528H12.31L7.913,64.8a.488.488,0,0,1,.035-.706.533.533,0,0,1,.733.034l5.185,5.5A.492.492,0,0,1,13.867,70.3Z" transform="translate(0 -63.964)" fill="#232226"/></svg></span>';
        // for (let i = 0; i < fraction.length; i++) {
        //     fraction[i].insertAdjacentHTML('beforeend', fractionEl);
        // }
        // // 各スライドの表示時間とアニメーションを連動
        // const slideDelay = [];
        //     for (let i = 0; i < slideLength; i++) {
        //     // HTML側の data-swiper-autoplay を取得
        //     let delay = Number(slide[i].getAttribute('data-swiper-autoplay'));
        //     slideDelay[i] = delay ? delay : myDelay; // HTMLに指定がなければ myDelay をセット
            
        //     // 各タグに transitionDuration / animationDuration をセット
        //     slide[i].querySelector('.circle-02').style.animationDuration = slideDelay[i] + 'ms';
        // }
        const circle = document.querySelector('.slide-fraction .circle')

        
        const SmallSlider = new Swiper ('.js-small-slider',{
            loop: true,
            easing:"linear",
            speed:200,
            allowTouchMove :false,
            simulateTouch:false,
            slidesPerView: 1,
            // initialSlide:1,
            spaceBetween: 0,
            // navigation: {
            //     nextEl: '.swiper-button-next',
            //     prevEl: '.swiper-button-prev',
            // },
            breakpoints: {
                992: {
                    spaceBetween: 0,
                }
            },
        })
        
        const NameSlider = new Swiper ('.js-name-slider',{
            loop: true,
            easing:"linear",
            speed:300,
            allowTouchMove :false,
            simulateTouch:false,
            slidesPerView: 1,
            initialSlide:0,
            // spaceBetween: 100,
            // navigation: {
            //     nextEl: '.js-name-slider .swiper-button-next',
            //     prevEl: '.js-name-slider .swiper-button-prev',
            // },
            breakpoints: {
                992: {
                    spaceBetween: 0,
                }
            },
        })

        const TitleSlider = new Swiper ('.js-title-slider',{
            loop: true,
            easing:"linear",
            speed:1000,
            allowTouchMove :false,
            simulateTouch:false,
            slidesPerView: 1,
            initialSlide:0,
            // spaceBetween: 100,
            // navigation: {
            //     nextEl: '.swiper-button-next',
            //     prevEl: '.swiper-button-prev',
            // },
            breakpoints: {
                992: {
                    spaceBetween: 0,
                }
            },
        })

        const LargeSlider = new Swiper ('.js-large-slider',{
            loop: true,
            effect: "creative",
            creativeEffect: {
                prev: {
                  translate: [0, 0, -1], // x,y,z軸についての距離
                  origin: "right bottom", // スライドの原点を指定
                },
        
                next: {
                  translate: [0, 0, 1], // x,y,z軸についての距離
                  origin: "right bottom", // スライドの原点を指定
                },
            },
            speed:600,
            allowTouchMove :false,
            simulateTouch:false,
            slidesPerView: 1,
            spaceBetween: 0,
            initialSlide:1,
            
            autoplay: {
                delay: 6000,
                disableOnInteraction:false,
            },
            navigation: {
                nextEl: '.large-slider .swiper-button-next',
                prevEl: '.large-slider .swiper-button-prev',
            },
            breakpoints: {
                992: {
                    spaceBetween: 0,
                }
            },
            on: {
                slideNextTransitionStart (){
                    if(LargeSlider){
                        const index = LargeSlider.realIndex
                        
                        TitleSlider.slideTo(index+1)
                        NameSlider.slideTo(index+1)
                        if(( index + 2 ) <  slide.length + 2 ){
                            SmallSlider.slideTo(index+2)
                            
                        }else{
                            SmallSlider.slideTo(0)
                        }
                    }
                },
                slideChangeTransitionStart: function (result) {
                    if(circle){
                        circle.classList.remove('-active')
                        circle.classList.add('-return')
                    }
                },
                slideChangeTransitionEnd: function (result) {
                    
                    if(circle){
                        circle.classList.add('-active')
                        circle.classList.remove('-return')
                    }
                }
            }
        })

        
    }
}